// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
    production: true,
    apiUrl: 'https://apiv2.bex.global/api/',
    nemApiUrls: 'https://bccon.bex.global/getNemIncoming',
    // nemApiUrls: "https://hbbccon.business-software.in/getNemIncoming",
    env: 'prod',
    showTestNetSections: false,
    captchaKey: '6LfPeVQUAAAAADV8drwrsUZfiWzj0B4VV6BZ18oc',
    gOAuthKey:
        '38075149790-5st3sqof16qirdrpqscl99u833398cbe.apps.googleusercontent.com',
    fbOAuthKey: '1505019676472820',
    inOAuthKey: '78mcz3l35ao1k2',
    // fsKey: "A00l8Syx2QtyKgSx2jVDtz",
    fsKey: 'AcsELHXpPQ8C0N18LxK8Nz',
    demoUrl: 'https://demo.bex.global?demoToken=',
    tvDatafeedUrl: 'https://apiv2.bex.global/statistics',
    bronceLevel: 5000,
    silverLevel: 10000,
    goldLevel: 5000000,
    qgenClientId: '6766536335b48e1e90dea2ac',
    qgenClientSecret: 'vTRU<Xp736~n8@95NrsMC%df-1[/c$0g'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
