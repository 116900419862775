import {Component, OnInit, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';
import {ApirequestService} from 'src/app/services/apirequest/apirequest.service';
import {ApiUrlService} from 'src/app/services/api-url/api-url.service';
import {DatumService} from 'src/app/services/datum/datum.service';
import { environment } from '../../../../../../environments/environment';
import { HandleSessionID, CreateIFrame, RegisterListener } from '../../../../../services/arritech-cfm-controller/arritech.service';
import { SnackbarService } from '../../../../../services/snackbar/snackbar.service';
import {Router} from '@angular/router';

@Component({
    selector: 'batp-bronze',
    templateUrl: './bronze.component.html',
    styleUrls: ['./bronze.component.css']
})
export class BronzeComponent implements OnInit {
    public QRCode: string;
    userDetails: any;
    token;
    public type: string;
    public level: string;
    public qgenSession: string;
    public showLoader = true;

    constructor(private _url: ApiUrlService,
                private _req: ApirequestService,
                private _sb: SnackbarService,
                private _router: Router,
                private _datum: DatumService) {
    }

    ngOnInit() {
        this.userDetails = this._datum.getUserDetails();

        // this.qrCode(userDetails.username);
        this.processQgenSteps();
    }

    processQgenSteps() {
        const kycSessionData = {
            level: this.userDetails.userLevel
        };

        this._req.fetchApiData(this._url.checkKycSessionUrl, kycSessionData, false).subscribe((res2: any) => { console.log(res2);
            if (res2.data) {
                this.qgenSession = res2.data.sessionId;
                this.initQgenSession();
            } else {
                this._sb.openSnackBar(
                    'KYC Session Error! Failed to initiate KYC Session. Please contact support.',
                    3000,
                    'batp_error_snackbar',
                    'center',
                    'top'
                );
            }
        });
    }

    initQgenSession() {
        HandleSessionID(this.qgenSession).then((sessRes) => {
            this.showLoader = false;
            CreateIFrame('qgen-kyc', 'bex-kyc');

            RegisterListener((eventData) => {
                console.log("Process completed: ");
                console.log(eventData);

                if (eventData.type === 'steps_completed_event' || eventData.type === 'close_cfm_event') {
                    const levelData = {
                        level: this.userDetails.userLevel
                    };
                    this._req.fetchApiData(this._url.updateKycLevelURL, levelData, false).subscribe((res2: any) => {
                    });
                    this._router.navigate(['/']);
                }
            });
        });
    }

    getUnixTimestamp() {
        return Math.floor(Date.now() / 1000);
    }

    qrCode(name: string): void {
        const data = {
            level: this.level,
            username: name
        };
        this._req.fetchApiData(this._url.getQRcodeUrl, data, false).subscribe((response: any) => {
            if (response) {
                this.QRCode = 'data:image/png;base64,' + response.data;
                // this.getAddWebHooks();
            }
        });
    }

    getAddWebHooks() {
        const data = {
            url: 'https://apollo.business-software.in/api/updateKmn'
        };
        this._req.fetchPost(this._url.webHooksAddUrl, data, this.token).subscribe((res: any) => {
        });
    }
}
