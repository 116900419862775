import { environment } from '../../environments/environment';

export class BatpConfig {
  // production  settings
  pollingURL:string = `https://ws.bex.global/polling`;
  chartURL:string = 'https://v3.bex.global/statistics/';
  apiURL:string = 'https://v3.bex.global/api/';
  authURL: string = 'https://auth.bex.global/api/';
  // qgenURL:string = 'https://qgenonline.com/integration-api/v1/public/';
  qgenURL:string = 'https://qgenonlinestaging.com/integration-api/v1/';

  // dev  settings
  /*apiURL:string = 'https://hbv3.business-software.in/api/';
  authURL: string = 'https://hbauth.business-software.in/api/';
  pollingURL:string = `https://hybsewebsocket.business-software.in/polling`;
  chartURL:string = 'https://hbv3.business-software.in/statistics/';
  qgenURL:string = 'https://qgenonlinestaging.com/integration-api/v1/';*/

  // apiURL:string = environment.apiUrl;
  // apiURL:string = 'https://apollo.hybse.com/api/';


  // apiURL = 'https://apiv2.hybse.com/api/';
  // authURL = 'https://stageauth.hybse.com/api/';

  // old_version
  // apiURL:string = 'https://stockv3.hybse.com/api/';
  // authURL: string = 'https://authv3.hybse.com/api/';

  // new_version
  // apiURL:string = 'https://stockv3.hybse.com/api/';
  // authURL: string = 'https://authv3.hybse.com/api/';
  // dev URL
  // kycURL = `https://dev.knowmenow.com/merchantnewclient/api/v1/`;
  // prod url
    kycURL = `https://hybseapi.knowmenow.com/api/v1/`;
}

export const fsKey: string = environment.fsKey;

export const devEnv: boolean = environment.production ? false : true;

export const captchaKey: string = environment.captchaKey;

export const nemApiUrl: string = environment.nemApiUrls;

export const batp_versionNo = '3.0.1';

export const batp_buildNo = '219461';

// export const batp_versionNo:string ='2.2.25';

// export const batp_buildNo:string ='218630';

export const _ibin_api_url = 'https://ibin.global/api/';

export const _tvDatafeedUrl = environment.tvDatafeedUrl;

// export const _tvDatafeedUrl = "https://apollo.business-software.in/statistics";
// export const _tvDatafeedUrl = "https://apiv2.hybse.com/api/statistics";
